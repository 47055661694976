import { Location } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import LocationMapMarkerContainer from '~/src/components/marketplace/locations/map/LocationMapMarkerContainer'
import { useEoValue } from '~/src/hooks/useEoState'
import { MARKETPLACE_LOCATIONS_STATE } from '~/src/stores/marketplace/locations'
import GoogleMap from '~/src/components/map/GoogleMap'

const LocationsMap = () => {
  const locations = useEoValue(MARKETPLACE_LOCATIONS_STATE)

  return (
    <Box
      sx={{
        height: 'inherit',
        width: '100%'
      }}
    >
      <GoogleMap
        id='locations__map__google_map'
        geocodes={locations.map((location: Location) => location.geoCode)}
      >
        {locations.map((location: Location, index: number) => (
          <LocationMapMarkerContainer
            key={index}
            id={location.id}
            position={{
              lat: Number(location.geoCode.lat),
              lng: Number(location.geoCode.long)
            }}
          />
        ))}
      </GoogleMap>
    </Box>
  )
}

export default LocationsMap
