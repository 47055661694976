import { MarkerProps } from '@react-google-maps/api'
import { useContext } from 'react'
import ResponsiveDialogContext from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { useEoValue } from '~/src/hooks/useEoState'
import CustomMapMarker from '~/src/components/map/CustomMapMarker'
import { FIRM_SELECTOR } from '~/src/stores/firm'

const LocationMapMarker = (otherProps: MarkerProps) => {
  const firm = useEoValue(FIRM_SELECTOR)
  const context = useContext(ResponsiveDialogContext)

  const _handleOnMarkerClick = () => {
    context?.toggleOpen && context.toggleOpen()
  }

  return (
    <CustomMapMarker
      mapMarker={firm?.settings.theme?.mapMarker}
      onClick={_handleOnMarkerClick}
      {...otherProps} />
  )
}

export default LocationMapMarker
