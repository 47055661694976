import { Filter, HttpError } from '@eo-storefronts/eo-core'
import { useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { fetchFilters } from '~/src/services/MarketplaceService'
import { BRAND_STATE } from '~/src/stores/brand'
import { useParams } from 'react-router-dom'

interface ReturnsType {
  data: Filter[] | undefined,
  fetch(categoryUUID?: string): Promise<Filter[] | undefined>,
  loading: boolean,
  error: HttpError | unknown | undefined,
}

const useFetchFilters = (): ReturnsType => {
  const { catId: categoryUUID } = useParams()
  const [ filters, setFilters ] = useState<Filter[] | undefined>(undefined)
  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState<HttpError | unknown | undefined>(undefined)
  const brand = useEoValue(BRAND_STATE)

  const fetch = async (uid?: string): Promise<Filter[] | undefined> => {
    if (!brand || !brand.id) {
      return
    }

    setFilters(undefined)
    setError(undefined)
    setLoading(true)

    try {
      const response = await fetchFilters(brand.id, uid ?? categoryUUID!)

      setFilters(response)

      return response
    } catch (e: HttpError | unknown) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  return { data: filters, fetch, loading, error }
}

export default useFetchFilters

